import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from './pages/homepage/homepage.component';
import FacultyPage from './pages/facultyPage/facultyPage.component';
import GalleryPage from './pages/galleryPage/gallerypage.component';
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import SignInAndSignUpPage from "./components/sign-in/sign-in.component";
import OnlineClasses from "./pages/onlineClasses/onlineclasses.component";
import "./app.scss";



import "react-alice-carousel/lib/alice-carousel.css";

function App() {
  return (
    <div className="App">
      <Header />

      <div className="container">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/faculty" component={FacultyPage} />
          <Route path="/gallery" component={GalleryPage} />
          <Route path="/signin" component={SignInAndSignUpPage} />
          <Route path="/onlineclasses" component={OnlineClasses} />
        </Switch>
      
        
      
      </div>

      <Footer/>
      
    </div>
  );
}

export default App;
