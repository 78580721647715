import React from "react";

import "./gallerypage.styles.scss";

const GalleryPage = () => (
  <div className="galleryPage">
    <div className="galleryPageHeading">
      <h2>School Photo Gallery</h2>
    </div>
    <div className="galleryPhotoContainer">
      
    {Array.apply(0, Array(16)).map(function (x, i) {
    return  <div
        className="galleryPhoto"
        style={{
          backgroundImage: `url(${"/gallery/img"+(i+1)+".jpg"})`,
        }}
      />;
  })}
  {Array.apply(0, Array(13)).map(function (x, i) {
    return  <div
        className="galleryPhoto"
        style={{
          backgroundImage: `url(${"/gallery/others"+(i+1)+".jpg"})`,
        }}
      />;
  })}
    </div>
  </div>
);

export default GalleryPage;
