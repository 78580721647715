import React from "react";
import AliceCarousel from "react-alice-carousel";

import "./homepage.styles.scss";

const HomePage = () => (
    
    <div className="homePage">
          <div className="imageGalleryBar">
            <AliceCarousel
              mouseTrackingEnabled
              autoPlay={false}
              autoPlayInterval="3000"
              duration="1000"
              dotsDisabled={false}
              fadeOutAnimation={false}
              buttonsDisabled={true}
            >
              <div
                className="galleryImgBox"
                style={{
                  backgroundImage: `url(${"gallery/img1.jpg"})`,
                }}
              />

              <div
                className="galleryImgBox"
                style={{
                  backgroundImage: `url(${"gallery/img2.jpg"})`,
                }}
              />
              <div
                className="galleryImgBox"
                style={{
                  backgroundImage: `url(${"gallery/img3.jpg"})`,
                }}
              />
              <div
                className="galleryImgBox"
                style={{
                  backgroundImage: `url(${"gallery/img4.jpg"})`,
                }}
              />
              <div
                className="galleryImgBox"
                style={{
                  backgroundImage: `url(${"gallery/img5.jpg"})`,
                }}
              />
              <div
                className="galleryImgBox"
                style={{
                  backgroundImage: `url(${"gallery/img6.jpg"})`,
                }}
              />
              <div
                className="galleryImgBox"
                style={{
                  backgroundImage: `url(${"gallery/img7.jpg"})`,
                }}
              />
            </AliceCarousel>
          </div>

          <div className="infoSection">
            <div className="sectionHeading">About Us</div>
            <div className="sectionContent">
              <div
                className="sectionContentImg"
                style={{
                  backgroundImage: `url(${"gallery/homepagemanager.jpg"})`,
                }}
              />

              <div className="sectionContentText">
                <ul>
                  <li>
                    KDR School is a very well reputed school of Kalyanpur,
                    Kanpur. It was established in 2005 and in the last 15 years
                    more than 1500 students passed from the school with very
                    good marks. We are committed to provide very good faculty to
                    all the students. Also, for needy students, we provide free
                    extra classed from our Faculty.
                  </li>
                  <li>
                    Our students have excelled in all the fields and have
                    established themselves very well in their respective
                    professions.
                  </li>
                  <li>
                    As a measure to give back to society, we provide concessions
                    to students of marginalised section of the society.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="infoSection">
            <div className="sectionHeading">Admission Open</div>
            <div className="sectionContent">
            <div
                className="sectionContentImg"
                style={{
                  backgroundImage: `url(${"gallery/homepagebadimadam.jpg"})`,
                }}
              />
            <div className="sectionContentText">
              <ul>
                <li>
                  We would like to inform you that{" "}
                  <strong>Admissions are Open in all the classes</strong> .
                </li>
                <li>
                  However, due to COVID-19 pandemic, the school will remain
                  closed till orders from Govt.
                </li>
                <li>
                  All the Guardians are requested to contact the Management at
                  phone no. 7270010226
                </li>
              </ul>
              </div>
            </div>
          </div>

          <div className="infoSection">
            <div className="sectionHeading">Online Classes</div>
            <div className="sectionContent">
              <ul>
                <li>
                  We are committed to provide classes to all our students in all
                  situations.
                </li>
                <li>
                  Accordingly,{" "}
                  <strong>
                    this website (https://kdrschool.com/) has been developed and
                    ONLINE CLASSES will start soon
                  </strong>
                  .
                </li>
                <li>
                  All students are requested to get their studentId's from the
                  management (phone no. 7270010226) for login to Student portal
                  for online classes.
                </li>
              </ul>
            </div>
          </div>
          <div className="infoSection">
            <div className="sectionHeading">15 Years in Service</div>
            <div className="sectionContent">
              <ul>
                <li>
                  On completion of 15 years, we would like to thank all the
                  Students & Guardians for making us part of their lives.
                </li>

                <li>
                  We would like to inform our Students and Guradians that
                  recently the School has gone through massive renovation. Now,
                  10 more class rooms including{" "}
                  <strong>
                    Computer Room, Library, Play room LKG and UKG{" "}
                  </strong>{" "}
                  have been added to School infrastructure.
                </li>
                <li>
                  Address: K.D.R. School, Gautam Vihar, New Sheoli Road,
                  Kalyanpur, Kanpur, 208017.
                </li>

                <li>
                  <strong>Contact Us at - 7270010226</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="infoSection">
            <div className="sectionHeading">Recent Updates</div>
            <div className="sectionContent">
              <ul>
                <li>
                  School has been renovated and <strong>10 extra rooms </strong>
                  have been added
                </li>
                <li>
                  <strong>Computer Room, Library and Play room for kids</strong>{" "}
                  has been recently added in School
                </li>
                <li>
                  <strong>Online Classes will start from July</strong>.{" "}
                </li>
                <li>
                  All students are requested to get their studentId's from the
                  management (phone no. 7270010226) for login to Student portal
                  for online classes.{" "}
                </li>
                <li>
                  All Students after taking their studentId's can go through the
                  Gallery Section of the website to check their event pics.
                </li>
              </ul>
            </div>
          </div>
          <div className="infoSection">
            <div className="sectionHeading">Request from Students</div>
            <div className="sectionContent">
              <ul>
                <li>
                  All the Students are requested to get their login credentials
                  from Management
                </li>
                <li>
                  Please wait for the orders from the Govt. Authorities for
                  School to open
                </li>
                <li>
                  <strong>ONLILNE CLASSES will start from JULY, 2020</strong>
                </li>
                <li>
                  Students/Guardians are requested to get their dues cleared.
                </li>
              </ul>
            </div>
          </div>
        </div>

);

export default HomePage;
