import React from "react";

import "./footer.styles.scss";

const Footer = () => (
  <div className="footer">
    <div className="footerContent">
      <span>
        This is official website of K.D.R. School, Kalyanpur, Kanpur. Copyright
        2020-2021. All Rights Reserved.
      </span>
      <br />
      <span>Contact us at - 7270010226.</span>
      <br />(<span>Powered by MontSoftwares Pvt. Ltd., Noida.</span>)
    </div>

  </div>
);

export default Footer;
