const FacultyData = [
    {
        fname : "Mr. Ravendra Singh",
        nickName : "R S Sir",
        gender: "male",
        imgName: "RSSir.jpg",
        qualification : "MA, B.Ed",
        subjects : "Maths, Science",
        otherInfo : ["He is the best faculty for teaching Mathematics in the School.", 
                    "He is having very vast experience in Maths and Science.",
                    "He is also a very good human being and very active in social work."],
        extra : "R S Sir"
    },
    {
        fname : "Mr. Surendra Kumar",
        nickName : "Surendra Sir",
        gender: "male",
        imgName: "SurendraSir.jpg",
        qualification : "MA, B.Ed",
        subjects : "English, Science",
        otherInfo : ["He is the best faculty for teaching English and Science in the School.", 
                    "He is having very vast experience in English and Science.",
                    "Students are very keen in learning with him as he teaches students with real life examples."],
        extra : "Surendra Sir"
    },
    {
        fname : "Mrs. Reena",
        nickName : "Reena Madam",
        gender: "female",
        imgName: "ReenaMadam.jpg",
        qualification : "Double M.A. & B.Ed",
        subjects : "Hindi, Sanskrit",
        otherInfo : ["She is excellent in the subjects she teaches. ", 
                    "She is having vast knowledge of Sanskrit and Hindi Language."],
        extra : "Reena Madam"
    },
    {
        fname : "Mr. Aman",
        nickName : "Aman sir",
        gender: "male",
        imgName: "AmanSir.jpg",
        qualification : "B.Sc.",
        subjects : "Maths, Science",
        otherInfo : ["He teaches Maths and Scince to the students. ", 
                    "He is having very good understanding of the subjects he teaches in School.",
                    "All the students are very satisfied with him."],
        extra : "Aman sir"
    },
    

];

export default FacultyData;

