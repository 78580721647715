import React from "react";
import InfoSection from "../../components/infoSection/infoSection.component";
import FacultyData from "./facultyData";

import "./facultyPage.styles.scss";

const FacultyPage = () => {
  const fdata = FacultyData;

  return (
    <div className="facultyPage">
      <div className="facultyPageHeading"><p>Our Star Faculty Members</p></div>

      <div className="facultyPage">
        {fdata.map((f) => (
          <InfoSection 
            fname={f.fname} 
            gender={f.gender}
            imgName={f.imgName} 
            qualification={f.qualification} 
            subjects = {f.subjects}
            otherInfo = {f.otherInfo}
            extra = {f.extra}
          />
        ))}
      </div>
    </div>
  );
};

export default FacultyPage;
