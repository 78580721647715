import React from "react";

import "./infoSection.styles.scss";

const InfoSection = ({fname, gender, imgName, qualification, subjects, otherInfo, extra}) => (
  
    <div className="infoSection">
      <div className="sectionHeading">{fname} </div>
      <div className="sectionContent">
        <div
          className="sectionContentImg"
          style={{
            backgroundImage: `url(${"/gallery/faculty/"+imgName})`,
          }}
        />

        <div className="sectionContentText">
          <ul>
            <li>{gender === "male"? "He":"She"} is a faculty of {subjects} </li>
            { otherInfo.map((item) => (<li>{item}</li>)) }
          </ul>
        </div>
      </div>
      <div className="extraInfo">{extra} ({qualification})</div>
    </div>
);

export default InfoSection;
