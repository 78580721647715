import  React from "react";
import './header.styles.scss';

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";


const Header = () => (
    <div className="headerContainer">
        <Navbar
          collapseOnSelect
          expand="sm"
          bg="dark"
          variant="dark"
          fixed="top"
        >
          <Navbar.Brand href="/">
            <img
              src="/kdr-logo.jpg"
              width="50"
              height="50"
              className="d-inline-block align-top logo"
              alt="KDR School Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/" className="headerItem">
                Home
              </Nav.Link>
              <Nav.Link href="/faculty" className="headerItem">
                Faculty
              </Nav.Link>
              <Nav.Link href="/gallery" className="headerItem">
                Gallery
              </Nav.Link>
              <Nav.Link href="/onlineclasses" className="headerItem">
                Online Classes
              </Nav.Link>
              
            </Nav>

            <Nav>
              <Nav.Link href="/signin" className="headerItem">
                LogIn
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
);

export default Header;